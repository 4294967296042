import { Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

import { AboutText, AboutDataContainer } from '../styles'
import { aboutHeader, aboutText } from '../utils/constants'
import Footer from '../components/Footer'

import {
  MiniPost,
  StyledArticlesSection,
  StyledImage,
  StyledSideBar,
  StyledAboutSection,
} from '../styles'

const SideBar = ({ posts }) => {
  return (
    <StyledSideBar>
      {posts && posts.length > 1 ? (
        <StyledArticlesSection>
          {posts.map(({ node: post }) => {
            const {
              frontmatter: { title, date, authorPicture, banner },
              fields: { slug },
            } = post
            return (
              <MiniPost key={slug}>
                <Link to={slug}>
                  {banner && (
                    <StyledImage fluid={banner.childImageSharp.fluid} />
                  )}
                </Link>
                <header>
                  <div>
                    <h3>
                      <Link to={slug}>{title}</Link>
                    </h3>
                    <time>{date}</time>
                  </div>
                  <div>
                    <Link to={slug}>
                      <Img fixed={authorPicture.childImageSharp.fixed} />
                    </Link>
                  </div>
                </header>
              </MiniPost>
            )
          })}
        </StyledArticlesSection>
      ) : null}
      <StyledAboutSection>
        <AboutDataContainer css={AboutText}>
          <h2>About</h2>
          <p>{aboutHeader}</p>
          <p>{aboutText}</p>
        </AboutDataContainer>
        <Footer />
      </StyledAboutSection>
    </StyledSideBar>
  )
}

export default SideBar
